(function (window) {
  'use strict';
  var report_sent = false;

  function getPerformanceMetrics() {
    var performance = window.performance || window.webkitPerformance || window.msPerformance || window.mozPerformance;

    if (performance === undefined) {
      return false;
    }
    var timing = performance.timing;
    return timing || false;
  }

  function createCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    if (xhr.withCredentials !== undefined) {
      // XHR for Chrome/Firefox/Opera/Safari.
      xhr.open(method, url, true);
    } else if (typeof XDomainRequest !== 'undefined') {
      // XDomainRequest for IE.
      // eslint-disable-next-line no-undef
      xhr = new XDomainRequest();
      xhr.open(method, url);
    } else {
      // CORS not supported.
      xhr = null;
    }
    return xhr;
  }

  function sendPerformanceData() {
    if (report_sent) {
      return false;
    }

    try {
      var timing = getPerformanceMetrics();
      // eslint-disable-next-line no-undef,no-prototype-builtins
      if (timing && _uptime_rum && _uptime_rum.hasOwnProperty('uuid')) {
        // Setup other payload items
        var payload = {
          type: 'rumdata',
          data: {
            rumdata: {
              timing: timing,
              user: {
                href: window.location.href,
                userAgent: window.navigator.userAgent,
              },
            },
            // eslint-disable-next-line no-undef
            uuid: _uptime_rum.uuid,
          },
        };

        // eslint-disable-next-line no-undef
        var xhr = createCORSRequest('POST', _uptime_rum.url);
        if (!xhr) {
          return false;
        }

        xhr.onload = function () {
          //alert('Response from CORS request: ' + xhr.responseText);
        };
        xhr.send(JSON.stringify(payload));
        // eslint-disable-next-line no-undef,no-prototype-builtins
      } else if (_uptime_rum && !_uptime_rum.hasOwnProperty('uuid')) {
        console.log('You are missing _uptime_rum.uuid property which needs to be global.');
      }
      report_sent = true;
      return true;
    } catch (e) {
      // Something went wrong.  Only by showing no data will we know if a JS error is happening
      return false;
    }
  }

  // eslint-disable-next-line no-undef
  if (_uptime_rum) {
    // eslint-disable-next-line no-undef
    _uptime_rum.sendReport = sendPerformanceData;
  }

  // eslint-disable-next-line no-undef,no-prototype-builtins
  if (!(_uptime_rum && _uptime_rum.hasOwnProperty('noAutoReport'))) {
    if (window.addEventListener) {
      window.addEventListener('load', sendPerformanceData, false);
    } else if (window.attachEvent) {
      window.attachEvent('onload', sendPerformanceData);
    } else {
      window.onload = sendPerformanceData;
    }
  }
})(window);
